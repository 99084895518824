/**
 * This script is loaded via analytics-loader.js when a user has given their consent to be tracked.
 *
 * What is does:
 * 1. Looks for elements with an '.analytics' class like this: 
 * 	 	<div class="analytics" data-type="zendesk" data-key="cfd44a6f-2728-4706-a9f3-b2d0d264a0ab"></div>
 * 2. Calls the function in the Analytics object below with the same name as the 'data-type' attribute, e.g. a type of 'zendesk'
 * would call the 'Analytics.zendesk' function.
 * 3. The function loads the 3rd party analytics script, performs some setup and records any events.
 *
 * See analytics-**.vm for examples of elements.
 */

const Cookie = require('Vendor/js-cookie');

module.exports = Analytics;

function Analytics() {

	this.init = function() {
		var consents = Cookie.get("gdpr-accept-privacy-consent") || "essential";

		console.log("Analytics.init()", consents);

		$('.analytics').each((i, el) => {
			this.type = $(el).data("type"); // Added to `this` to avoid passing it from function to function
			var group = $(el).data("group");
			var fn    = this.camelCase(this.type);

			// Get the function to call + call it.
			if(this[fn] && (consents === "true" || consents.includes(group))) {
				this[fn]($(el));
			}
		});
	}

	this.addScript = function(code, nameOverride) {
		var name = nameOverride || ('analytics-' + this.type);
		var isLink = code.startsWith("//") || code.startsWith("http");

		// Need to check that the tag doesn't already exist, as some are loaded anyway via data-always="true"
		if(!$('#'+name).length) {
			console.log('Analytics.addScript()', name);
			$('head').append('<script async id="' + name + '"' + (isLink ? 'src="' + code + '"' : '') + '>' + (isLink ? '' : code) + "</script>");
		}
	}

	this.addPixel = function(url, nameOverride) {
		var name = nameOverride || ('analytics-' + this.type);

		// Need to check that the pixel doesn't already exist
		if(!$('#'+name).length) {
			console.log('Analytics.addPixel()', name, url);
			let img = document.createElement('img');
			img.id = name;
			img.src = url;
			img.width = 1;
			img.height = 1;
			img.style.position = "absolute";
			$('body').append(img);
		}
	}

	this.facebook = function(domElement) {
		var code = "!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};" +
			"if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='6.0';"+
			"n.queue=[];t=b.createElement(e);t.async=!0;"+
			"t.src=v;s=b.getElementsByTagName(e)[0];"+
			"s.parentNode.insertBefore(t,s)}(window,document,'script', 'https://connect.facebook.net/en_US/fbevents.js');"+
			"fbq('init', '" + domElement.data('account') +"');"+
			"fbq('track', 'PageView');";

		var event       = domElement.data("event");
		var eventCustom = domElement.data("eventCustom");

		if(event && event != "") {
			code += "fbq('" + (eventCustom ? "trackCustom" : "track") + "', '" + event + "');";
		}

		this.addScript(code);
	}

	this.facebookPage = function(domElement) {
		$('body').append('<div id="fb-root"></div>');
		
		var code = "(function(d, s, id) {"+
			"var js, fjs = d.getElementsByTagName(s)[0];"+
			"if (d.getElementById(id)) return;"+
			"js = d.createElement(s); js.id = id;"+
			"js.src = 'https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v10.0&appId=" + domElement.data('account') + "&autoLogAppEvents=1';"+
			"fjs.parentNode.insertBefore(js, fjs);"+
			"}(document, 'script', 'facebook-jssdk'));"

		this.addScript(code);
	}

	this.googleAds = function(domElement) {
		var code  = "/* <![CDATA[ */";
		var data = domElement.data();
		
		$.each(data, function(key, value) {
			if(key != 'account') {
				code += "var " + key + "=";

				if($.isNumeric(value) || typeof value === "boolean") {
					code += value + ";";
				}
				else {
					code += '"' + value + '";';
				}
			}
		});

		code += "/* ]]> */";

		this.addScript(code);
		this.addScript('//www.googleadservices.com/pagead/conversion.js', 'analytics-google-ads-2');
	}

	this.googleAnalytics = function(domElement) {
		var variation = domElement.data('variation');
		var anon      = domElement.data('anon');

		var code = "(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','//www.google-analytics.com/analytics.js','ga');ga('set', 'anonymizeIp', " + anon + ");ga('create','" + domElement.data('account') + "', 'auto');ga('send','pageview'" + (variation && variation != "" ? '' : ",{'dimension1':'" + variation + "'}") + ");";

		var eventKey = domElement.data('eventKey');

		if(eventKey && eventKey != "") {
			code += "ga('send','event', '" + eventKey + "','" + domElement.data('eventValue') + "');"
		}

		var optimise = domElement.data('optimise')

		if(optimise && optimise != "") {
			code += "ga('require','" + optimise + "');"
		}

		this.addScript(code);

		if(optimise && optimise != "") {
			code = "<style>.async-hide{opacity:0!important}</style>";

			$('head').append(code);

			code = "(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};"+
			"(a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;})(window,document.documentElement,'async-hide','dataLayer',4000,{'" + optimise + "':true});"

			this.addScript(code, 'analytics-optimise');
		}
	}

	this.googleTagManager = function(domElement) {
		var event     = domElement.data("event");
		var variation = domElement.data("variation");
		var loggedin  = domElement.data('loggedIn');
		var userid    = domElement.data('userId');
		var charityid = domElement.data('charityId');
		var corporateid = domElement.data('corporateId');
		var anon      = domElement.data('anon');

		var code = "window.dataLayer = window.dataLayer || [];" + // dataLayer must be set and added to before GTM script below.
		(variation ? "window.dataLayer.push({'variation':[" + variation.split(',').map(v => "'" + v + "'").join() + "]});" : "") +
		(userid ? "window.dataLayer.push({'userid': '" + userid + "'});" : "") +
		(charityid ? "window.dataLayer.push({'charityid': '" + charityid + "'});" : "") +
		(corporateid ? "window.dataLayer.push({'corporateid': '" + corporateid + "'});" : "") +
		(anon ? "window.dataLayer.push({'anon': 'true'});" : "") +
		"window.dataLayer.push({'loggedin': '" + (loggedin || "false") + "'});" +
		(event ? "window.dataLayer.push({'event': '" + event + "'});" : "") + // this must come after the other dataLayer bits, otherwise that data will be missing from custom events
		"document.querySelectorAll('input,textinput,select,option,file').forEach(item=>{item.addEventListener('focus',event=>{dataLayer.push({'event':'formFieldFocus','gtm.element':event.target,'gtm.elementClasses':event.target.className||'','gtm.elementId':event.target.id||'','gtm.elementTarget':event.target.target||'','gtm.elementUrl':event.target.href||event.target.action||'','gtm.originalEvent':event})},true)});"+
		"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':"+
		"new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],"+
		"j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src="+
		"'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);"+
		"})(window,document,'script','dataLayer','" + domElement.data('account') + "');";

		this.addScript(code);

		code = '<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=' + domElement.data('account') + '" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>';

		$('body').prepend(code);
	}

	this.heap = function(domElement) {
		var code = 'window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};';

		var profile   = domElement.data('profile');
		var variation = domElement.data('variation');
		var charityId = domElement.data('charityId');
		var event     = domElement.data("event");

		code += 'heap.load("' + domElement.data('account') + '");'+
			(profile ? 'heap.identify("' + profile + '");heap.addEventProperties({"loggedin":"true"});' : 'heap.removeEventProperty("loggedin");') +
			(variation ? 'heap.addEventProperties({"variation":"' + variation + '"});' : '') + 
			(charityId ? 'heap.addEventProperties({"charityId":"' + charityId + '"});' : '') + 
			(event ? 'heap.track("' + event + '");' : '');

		this.addScript(code);
	}

	/*
	this.heyflow = function(domElement) {
		var account = domElement.data('account');

		$('head').append('<script async src="https://app.heyflow.co/pixel/' + account + '"></script>');
	}
	*/

	this.hotjar = function(domElement) {
		var code = "(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:" + domElement.data('account') + ",hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');";

		this.addScript(code);
	}

	this.leadForensics = function(domElement) {
		this.addScript("https://secure.24-information-acute.com/js/" + domElement.data('account') + ".js");
	}

	this.pushalert = function(domElement) {
		var key  = domElement.data('key');
		var code = "(function(d, t){var g=d.createElement(t),s=d.getElementsByTagName(t)[0];g.src='https://cdn.pushalert.co/integrate_" + key + ".js';s.parentNode.insertBefore(g,s);}(document,'script'));";
		code += "\
			(pushalertbyiw = window.pushalertbyiw || []).push(['onReady', function () {\
				var resultContainer = document.querySelector('.js-pushalert-subscriber-id');\
				if(resultContainer) {\
					resultContainer.innerHTML = PushAlertCo.subs_id\
				}\
			}]);\
		";
		this.addScript(code);
	}

	this.redeye = function(domElement) {
		var baseData = domElement.data();
		delete baseData.group;
		delete baseData.type;

		// We only track certain events with Redeye - so nothing fires here, we just create a global function that can be called at any time
		window.gaylRedeye = (data) => {
			var qs = new URLSearchParams({...baseData, ...data});
			var url = 'https://reporting.giveasyoulive.com/cgibin/rr/blank.gif?' + qs.toString().replaceAll("\+","%20");
			this.addPixel(url);
		}
	}

	this.sentry = function(domElement) {
		var data = domElement.data(); // Extract data attributes
		var config = {
				dsn: 'https://' + data.key + '@sentry.io/' + data.account,
				environment: data.env,
				allowUrls: [data.domain + '/scripts/'] // Only send events for errors thrown by scripts on our website, not browser extensions! Can be either a string or regex.
		};

		this.loadScript("https://browser.sentry-cdn.com/9.11.0/bundle.min.js", function() {
			Sentry.init(config);

			// If the user is logged in, provide some more information to Sentry
			if(data.uid) {
				Sentry.withScope(scope => {
					scope.setUser({
						id: data.uid,
						email: data.email
					});
				});
			}
		});
	}

	this.twitter = function(domElement) {
		var code = "!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);" +
			"},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js'," +
			"a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');" +
			"twq('init','" + domElement.data('account') + "');"+
			"twq('track','PageView');";

		this.addScript(code);
	}

	this.zendesk = function(domElement) {
		var data = domElement.data();

		window.zESettings = {
			webWidget: {
				position: { horizontal: (data.isMobile ? 'left' : 'right'), vertical: 'bottom' },
				contactForm: {
				  fields: [
					{ id: 'name', prefill: { '*': data.name } },
					{ id: 'email', prefill: { '*': data.email } },
					{ id: 22999688, prefill: { '*': data.charity } },
					{ id: 10417641632404, prefill: { '*': data.corporate } },
				  ]
				}
			}
		};

		this.addScript('https://static.zdassets.com/ekr/snippet.js?key=' + data.key, 'ze-snippet'); // Has to be ze-snippet, otherwise script doesn't work
	}
	
	/**
	 * Loads a script and fires 'callback' when it's loaded.
	 */
	this.loadScript = function(src, callback) {
		var script = document.createElement('script');
		script.async = 1;
		script.crossOrigin = 'anonymous';
		script.src = src;
		script.onload = function() {
			callback();
		}
		document.head.appendChild(script);
	}

	this.camelCase = function(s) {
		return s.replace(/([-_][a-z])/ig, ($1) => $1.toUpperCase().replace('-', '').replace('_', ''));
	}

	this.init();
}
